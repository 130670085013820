define("discourse/plugins/karma-score/discourse/templates/components/karma-loading", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="karma-loading" style="width:100%;text-align:center;margin-top:2em">
    {{d-icon "circle"}}
    {{d-icon "circle"}}
    {{d-icon "circle"}}
    {{#if message.length}}
      <p>
        {{message}}
      </p>
    {{/if}}
  </div>
  
  */
  {
    "id": "20moMCgp",
    "block": "[[[10,0],[14,0,\"karma-loading\"],[14,5,\"width:100%;text-align:center;margin-top:2em\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"circle\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"circle\"],null]],[1,\"\\n  \"],[1,[28,[35,1],[\"circle\"],null]],[1,\"\\n\"],[41,[30,0,[\"message\",\"length\"]],[[[1,\"    \"],[10,2],[12],[1,\"\\n      \"],[41,[28,[32,0],[\"message\"],null],[[[8,[39,4],null,null,null]],[]],[[[44,[[28,[37,6],null,[[\"message\"],[[28,[32,1],[\"message\"],null]]]]],[[[1,[52,[30,1,[\"message\"]],[28,[30,1,[\"message\"]],null,null],[28,[32,2],[[30,0],\"message\",\"[\\\"The `message` property path was used in the `discourse/plugins/karma-score/discourse/templates/components/karma-loading.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.message}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[1,[28,[32,3],[\"[[\\\"The `message` property path was used in the `discourse/plugins/karma-score/discourse/templates/components/karma-loading.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.message}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"div\",\"d-icon\",\"if\",\"p\",\"message\",\"let\",\"hash\"]]",
    "moduleName": "discourse/plugins/karma-score/discourse/templates/components/karma-loading.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});